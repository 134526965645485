import React, { FC, Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Zendesk from 'react-zendesk';
import config from './config';

import Loader from './components/Loader';
import * as routes from 'src/routes/routeUrl';
const PrivateRouter = lazy(() => import('./components/PrivateRouter'));
const Design = lazy(() => import('src/scenes/design'));
const LogIn = lazy(() => import('./scenes/Login'));
const Logout = lazy(() => import('./scenes/Logout'));
const ForgetPassword = lazy(() => import('./scenes/ForgetPassword'));
const PrivateRoutes = lazy(() => import('./PrivateRoute'));

// const setting = {
//   color: {
//     theme: '#abc',
//   },
//   launcher: {
//     chatLabel: {
//       'en-US': 'Need Help',
//     },
//   },
//   contactForm: {
//     fields: [{ id: 'description', prefill: { '*': 'My pre-filled description' } }],
//   },
// };

const Routes: FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {/* Design Route */}
        {(process.env.NODE_ENV !== 'production' || config?.baseUrl?.includes('dev')) && (
          <Route path={routes.DESIGN} component={Design} />
        )}
        <Route exact path={routes.LOGIN} component={LogIn} />
        <Route exact path={routes.LOGOUT} component={Logout} />
        <Route exact path={routes.FORGET_PASSWORD} component={ForgetPassword} />
        <PrivateRouter path={routes.HOME} component={PrivateRoutes} />
      </Switch>
      {/* {config.zendeskKey && (
        <Zendesk
          defer
          zendeskKey={config.zendeskKey}
          {...setting}
          onLoaded={() => {
            ZendeskAPI('messenger:set', 'cookies', false);
          }}
        />
      )} */}
      <ToastContainer />
    </Suspense>
  );
};
export default Routes;
